import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WorkInfo from "../../components/work-info"
import { StaticImage } from "gatsby-plugin-image"
import ProjectCard from "../../components/project-card"
import VirginiaSin from "../../images/projects/sin/cover.jpg"
import Versed from "../../images/projects/versed/cover.jpg"

const aurisPage = () => (
  <Layout>
    <SEO title="Kindbody" />
    <WorkInfo 
      title="Kindbody"
      link="shop.kindbody.com"
      team1="Bailey Latimer"
      team2="Michael Budde"
      team3="Angela Zhou"
      challenge="Creating an inclusive space for direct at-home fertility care."
      solution="Creating an inclusive space for direct at-home fertility care."/>

    <StaticImage class="mb-6" src="../../images/projects/kindbody/cover.jpg" alt="Kindbody Hero" aspectRatio={16/7} layout={"fullWidth"}/>

      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/kindbody/pdp--details.jpg" alt="Kindbody About Mobile" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-6" src="../../images/projects/kindbody/mobile--01.jpg" alt="Kindbody Start the conversation" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
      <StaticImage class="mb-6" src="../../images/projects/kindbody/home--about.jpg" alt="Kindbody Home About" layout={"fullWidth"}/>
      <div class="columns-1 lg:columns-2">
        <StaticImage class="mb-6" src="../../images/projects/kindbody/browser.jpg" alt="Kindbody Browser Preview" layout={"fullWidth"} aspectRatio={4/5}/>
        <StaticImage class="mb-20" src="../../images/projects/kindbody/home--desktop.jpg" alt="Kindbody Home Preview" layout={"fullWidth"} aspectRatio={4/5}/>
      </div>
   
      <div class="adv-gallery-v3 mb-20">
        <h4 className="color-contrast-higher mb-4 pl-8 lg:pl-0">Related</h4>
        <ul class="columns-1 lg:columns-2">
          <ProjectCard classes="" title="Versed Skin" url="versed-skin"  description="Revolutionizing the natural skincare industry with all-natural, vegan ingredients for cleansers, moisturizers, serums, masks." cover={Versed} role="Development" />

          <ProjectCard classes="" title="SIN" url="virginia-sin"  description="Building a minimalist site for the Brooklyn-based, handmade ceramics and home goods brand." cover={VirginiaSin} role="Design + Development"/>
        </ul>
      </div>


  </Layout>
)

export default aurisPage
